import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import Layout from '../../components/Layout'
import Cate from '../../components/Categories/Categories'
import { getWishlistItems, removeItemFromWishlist } from '../../store/wishlist'
import Section from '../../components/Layout/Section'
import Title from '../../components/Layout/Title'

const Wishlist = () => {
  const items = useSelector(state => state.wishlist.items)
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const currentUser = useSelector(state => state.auth.user)
  const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    console.log('123123213')
    fetch('/products.json')
      .then(res => res.json())
      .then(res => {
        setAllProducts(res)
      })
  }, [])

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }

    dispatch(getWishlistItems())
  }, [token, dispatch, getWishlistItems])

  const products = useMemo(
    () =>
      !!items
        ? items
            .map(item => {
              const product = allProducts.find(p => p.sku === item.product.sku)
              if (!product) return null
              return {
                ...product,
                wishlistItem: {
                  ...item,
                },
              }
            })
            .filter(item => item)
        : [],
    [allProducts, items]
  )

  const removeFromWishlist = useCallback(
    id => {
      if (!token) {
        navigate('/login')
        return
      }
      dispatch(removeItemFromWishlist(id))
    },
    [token, dispatch, removeItemFromWishlist]
  )

  return (
    <Layout>
      <Section>
        <Title title="Wishlist" className="text-center" />
        {!!products && products.length > 0 ? (
          <Container>
            <Cate
              items={products}
              pageInfo={{
                currentPage: 1,
                totalPage: 1,
              }}
              onPaginationItemClick={() => {}}
              btnText="Remove from wishlist"
              btnOnClick={removeFromWishlist}
              category={{
                name: '',
              }}
              forceShowProducts={true}
            />
          </Container>
        ) : (
          <Container>
            <p>No item in your wishlist</p>
          </Container>
        )}
      </Section>
    </Layout>
  )
}

export default Wishlist
