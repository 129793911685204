import React, { useState, useCallback } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, navigate } from 'gatsby'
import { formatPrice } from '../../helpers/price'
import { buildProductName, getProductUrlByCategory } from '../../helpers/theme'
import Product from '../../models/product'
import FeaturedProductModal from '../FeaturedProduct/FeaturedProductModal'
import ProductThumbnail from '../Product/Thumbnail'
import { addItemToWishlist } from '../../store/wishlist'
import { useSelector } from 'react-redux'

const Categories = ({
  items,
  category,
  visible,
  btnOnClick = () => {},
  btnText = '',
}) => {
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [modalShow, setModalShow] = useState(false)
  const [popupItem, setPopupItem] = useState()

  const ShowPopup = item => e => {
    e.preventDefault()
    setPopupItem(item)
    setModalShow(true)
  }
  return (
    <div>
      <Row className="product-list">
        {items.slice(0, visible).map((_item, idx) => {
          let item = new Product(_item, priceRuleItems)

          // if (_item.variants && _item.variants.length) {
          //   item = new Product(_item.variants[0].product, priceRuleItems)
          // }

          const nameArray = buildProductName(item.name)
          const productName = nameArray[0]
          return (
            <Col xs={6} md={6} lg={3} key={`category-${idx}`}>
              <Link to={getProductUrlByCategory(item, category.id)}>
                <div className="featured-item">
                  {/*<button className="btn-like">*/}
                  {/*  <svg viewBox="0 0 512 512" width="28" height="28">*/}
                  {/*    <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />*/}
                  {/*  </svg>*/}
                  {/*</button>*/}
                  <div className="featured-item-inner">
                    <div className="featured-item-image">
                      <ProductThumbnail product={item} />
                      {/* <span className="tags">OUT OF STOCK</span> */}
                      <button
                        className="featured-item-view"
                        onClick={ShowPopup(item)}
                      >
                        Quick View
                      </button>
                    </div>
                    <div className="h4">{productName}</div>
                  </div>
                  <div className="featured-item-des">
                    {item.isBundle || item.isConfigurable ? (
                      <Row>
                        <Col xs={12} md={6}>
                          <strong className="price-label">
                            {item.isBundle && <span>From:&nbsp;</span>}
                            {formatPrice(
                              item.minPrice + (item.minPrice * 10) / 100,
                              item.currency
                            )}
                            {item.minFullPrice > item.minPrice && (
                              <>
                                <br />
                                <s className="old-price">
                                  {formatPrice(
                                    item.minFullPrice +
                                      (item.minFullPrice * 10) / 100,
                                    item.currency
                                  )}
                                </s>
                              </>
                            )}
                          </strong>

                          <p className="text-sm">
                            Excl. GST:{' '}
                            {formatPrice(item.minPrice, item.currency)}
                          </p>
                        </Col>
                        {item.maxPrice > item.minPrice && (
                          <Col xs={12} md={6}>
                            <strong className="price-label">
                              {item.isBundle && <span>To:&nbsp;</span>}
                              {formatPrice(
                                item.maxPrice + (item.maxPrice * 10) / 100,
                                item.currency
                              )}
                              {item.maxFullPrice > item.maxPrice && (
                                <>
                                  <br />
                                  <s className="old-price">
                                    {formatPrice(
                                      item.maxFullPrice +
                                        (item.maxFullPrice * 10) / 100,
                                      item.currency
                                    )}
                                  </s>
                                </>
                              )}
                            </strong>

                            <p className="text-sm">
                              Excl. GST:{' '}
                              {formatPrice(item.maxPrice, item.currency)}
                            </p>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <>
                        <strong>
                          {formatPrice(
                            item.salePrice + (item.salePrice * 10) / 100,
                            item.currency
                          )}
                        </strong>
                        {item.price > item.salePrice && (
                          <strong>
                            &nbsp;
                            <s>
                              {formatPrice(
                                item.price + (item.price * 10) / 100,
                                item.currency
                              )}
                            </s>
                          </strong>
                        )}

                        <p className="text-sm">
                          Excl. GST:{' '}
                          {formatPrice(item.salePrice, item.currency)}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </Link>
              {!!btnText && (
                <p>
                  <a
                    href="#"
                    className="d-block"
                    onClick={e => {
                      e.preventDefault()
                      btnOnClick(_item.wishlistItem.id)
                    }}
                  >
                    {btnText}
                  </a>
                </p>
              )}
            </Col>
          )
        })}
      </Row>
      {!!popupItem && (
        <FeaturedProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          item={popupItem}
        />
      )}
    </div>
  )
}

export default Categories
